var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"dkzc-box"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: 'index-syllabus' }}},[_vm._v("考纲教材")]),_c('el-breadcrumb-item',[_vm._v("视频讲座")])],1),(_vm.list.length)?_c('div',{staticClass:"group"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"video",on:{"click":function($event){return _vm.$router.push({
            path: '/index-syllabus-videoLectureDetail',
            query: {
              video_uid: item.video_uid,
              book_uid: _vm.$route.query.book_uid,
            },
          })}}},[_c('img',{staticClass:"banner",attrs:{"src":item.avatar}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.video_name))]),_c('div',{staticClass:"browse"},[_c('span',[_vm._v(_vm._s(item.browse_num > 10000 ? (item.browse_num - (item.browse_num % 1000)) / 10000 + "万" : item.browse_num))]),_c('span',[_vm._v("浏览")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm._f("formaTime")(item.add_time)))])]),_c('span')])}),0):_c('div',{staticClass:"boxNull"},[_c('div',{staticClass:"null-box"},[_c('img',{attrs:{"src":_vm.$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png',"alt":""}}),_c('div',{staticClass:"title"},[_vm._v("暂无数据")])])]),(_vm.total > 9)?_c('div',{staticClass:"foot-page"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":9,"layout":"prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }