<template>
  <div class="page">
    <div class="dkzc-box">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: 'index-syllabus' }"
          >考纲教材</el-breadcrumb-item
        >
        <el-breadcrumb-item>视频讲座</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="group" v-if="list.length">
        <div
          class="video"
          v-for="(item, index) in list"
          :key="index"
          @click="
            $router.push({
              path: '/index-syllabus-videoLectureDetail',
              query: {
                video_uid: item.video_uid,
                book_uid: $route.query.book_uid,
              },
            })
          "
        >
          <img class="banner" :src="item.avatar" />
          <span class="title">{{ item.video_name }}</span>
          <div class="browse">
            <span>{{
              item.browse_num > 10000
                ? (item.browse_num - (item.browse_num % 1000)) / 10000 + "万"
                : item.browse_num
            }}</span>
            <span>浏览</span>
            <span class="time">{{ item.add_time | formaTime }}</span>
          </div>
          <span></span>
        </div>
      </div>
      <div v-else class="boxNull">
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
      <div class="foot-page" v-if="total > 9">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="9"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
    };
  },
  async created() {
    this.xin.isLoadding(true);
    await this.getBookVideoLists(this.$route.query.book_uid);
    this.xin.isLoadding(false);
  },
  methods: {
    async handleSizeChange(val) {
      this.page = val;
      this.xin.isLoadding(true);
      await this.getBookVideoLists(this.$route.query.book_uid);
      this.xin.isLoadding(false);
    },
    async handleCurrentChange(val) {
      this.page = val;
      this.xin.isLoadding(true);
      await this.getBookVideoLists(this.$route.query.book_uid);
      this.xin.isLoadding(false);
    },
    async getBookVideoLists(id) {
      let res = await this.$newsApi.getBookVideoLists({ book_uid: id });
      if (res.code == 200) {
        this.list = res.data.data;
        this.total = res.data.total;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  .foot-page {
    margin-top: 60px;
    height: 40px;
    text-align: center;
    width: 100%;
  }
  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-right: 10px;
    }
    > .suffix {
      margin-top: 2px;
    }
    > .group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-between; //内容向两边靠
      > .video {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        background: #f2f2f2;
        color: #8f8f8f;
        margin-bottom: 15px;
        margin-right: 15px;
        > .banner {
          width: 390px;
          height: auto;
          max-height: 260px;
          -o-object-fit: cover;
          object-fit: cover;
        }
        > .title {
          font-size: 18px;
          color: #333333;
          margin: 10px 0 10px 10px;
        }
        > .browse {
          display: flex;
          flex-direction: row;
          margin: 0 0 15px 10px;
          > .time {
            margin-left: 15px;
          }
        }
      }
      .video:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .boxNull {
    margin-top: 60px;
    text-align: center;
  }
}
</style>
